export const analyticsLabels = {
    BotSignature: 'User-Agent contains bot signature',
    Headless: 'Detected Headless Browser',
    NavProps: 'Suspicious navigator properties',
    PerfAPI: 'Unusual Performance API behavior',
    NoInteraction: 'No real user interaction detected',
};

const useDetectBot = () => {
    const failedChecks = [];

    // Ignore bot detection if Puppeteer prebuild is running
    if (window.__PUPPETEER_PREBUILD_MODE__) {
        return { isBot: false, failedChecks: [] };
    }

    // Check User-Agent String
    const isBotByUserAgent = () => {
        const botPatterns = [
            /bot/i,
            /crawl/i,
            /spider/i,
            /slurp/i,
            /mediapartners/i,
            /facebook/i,
            /baiduspider/i,
            /yandex/i,
            /duckduckbot/i,
            /bingbot/i,
            /googlebot/i,
        ];
        const isBot = botPatterns.some((pattern) =>
            pattern.test(navigator.userAgent)
        );

        if (isBot) failedChecks.push('BotSignature');

        return isBot;
    };

    // Detect Headless Browsers (Puppeteer, Selenium)
    const isHeadlessBrowser = () => {
        const w = window;
        const isHeadless =
            !window.__PUPPETEER_PREBUILD_MODE__ &&
            (w.navigator.webdriver || // Detects Puppeteer, Selenium, etc.
                /HeadlessChrome/.test(w.navigator.userAgent) || // Detects Chrome in headless mode
                !w.outerWidth ||
                !w.outerHeight); // Detects minimal browser environments

        if (isHeadless) failedChecks.push('Headless');

        return isHeadless;
    };

    // Detect Suspicious `navigator` Properties
    const isNavigatorSuspicious = () => {
        const missingLanguages =
            !navigator.languages || navigator.languages.length === 0;
        const missingHardwareConcurrency =
            !navigator.hardwareConcurrency || navigator.hardwareConcurrency < 1;
        const isWebDriver =
            navigator.webdriver === true && !window.__PUPPETEER_PREBUILD_MODE__; // Exclude Puppeteer
        const isSuspicious =
            missingLanguages || missingHardwareConcurrency || isWebDriver;

        if (isSuspicious) failedChecks.push('NavProps');

        return isSuspicious;
    };

    // Detect Suspicious `performance` API Activity
    const isPerformanceSuspicious = () => {
        const isSuspicious =
            performance.getEntriesByType('navigation').length === 0;

        if (isSuspicious) failedChecks.push('PerfAPI');

        return isSuspicious;
    };

    const botUserAgent = isBotByUserAgent();
    const headlessBrowser = isHeadlessBrowser();
    const suspiciousNavigator = isNavigatorSuspicious();
    const suspiciousPerformance = isPerformanceSuspicious();

    const totalFailedChecks = [
        botUserAgent,
        headlessBrowser,
        suspiciousNavigator,
        suspiciousPerformance,
    ].filter(Boolean).length;

    return { isBot: totalFailedChecks >= 1, failedChecks };
};

export default useDetectBot;
